import React from 'react'

// import Layout from '../components/layout'
// import Seo from '../components/seo'
// import BackgroundImage from 'gatsby-background-image'

// import { graphql, useStaticQuery } from 'gatsby'

// ===================Redirect========================
// import { useEffect } from 'react';
// import { navigate } from 'gatsby';

// export default () => {
//   useEffect(() => {
//     navigate('/blog/');
//   }, []);
//   return null;
// };
//====================================================

const IndexPage = () => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(
  //         relativePath: { eq: "hero-casual-man-with-arms-crossed.jpg" }
  //       ) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //       section: file(
  //         relativePath: { eq: "billboard-wesleyan-head-office.jpg" }
  //       ) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  // const imageData = data.desktop.childImageSharp.fluid
  // const imageSection = data.section.childImageSharp.fluid

  return (
    <></>
    // <Layout className="layout">
    //   <Seo title="Home" />
    //   <div className="component content">
    //     <div className="component-content">
    //       <div className="component snippet">
    //         <div className="component-content">
    //           <div className="snippet-inner">
    //             <div className="snippet-container">
    //               <div className="snippet">
    //                 <div role="banner">
    //                   <div className="wes-hero-banner-2">
    //                     <div className="wes-hero-banner-2__wrapper">
    //                       <div className="wes-hero-banner-2__body">
    //                         <div className="wes-hero-banner-2__content">
    //                           <h1 className="wes-hero-banner-2__title field-hero-title">
    //                             Wesleyan Bank
    //                           </h1>
    //                           <div className="wes-hero-banner-2__message">
    //                             <div
    //                               className="field-hero-text"
    //                               role="heading"
    //                               aria-level="2"
    //                             >
    //                               <p>Personal and business finance</p>
    //                             </div>
    //                           </div>
    //                           <div className="wes-hero-banner-2__footnote">
    //                             <p></p>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <BackgroundImage
    //                       className="wes-hero-banner-2__image"
    //                       style={{
    //                         backgroundSize: "cover",
    //                         backgroundPosition: "45% center",
    //                         backgroundRepeat: "no-repeat",
    //                         position: "absolute",
    //                       }}
    //                       fluid={imageData}
    //                     />
    //                     <div className="wes-hero-banner-2__shapes"></div>
    //                   </div>
    //                 </div>

    //                 <div className="component wes-emphasis">
    //                   <div className="wes-emphasis__wrapper">
    //                     <div className="wes-emphasis__body">
    //                       <div className="wes-emphasis__body-content">
    //                         <div className="wes-emphasis__frame">
    //                           <div className="wes-emphasis__message">
    //                             <p>
    //                               Wesleyan Bank helps both individuals and
    //                               businesses do more with their money - with a
    //                               range of personal savings accounts and ISAs as
    //                               well as specialist business funding solutions.
    //                             </p>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>{" "}
    //                 </div>

    //                 <div className="component wes-billboard wes-billboard--grey">
    //                   <div className="wes-billboard__wrapper">
    //                     <div className="wes-billboard__body">
    //                       <div className="wes-billboard__body-content">
    //                         <div className="wes-billboard__content">
    //                           <h2 className="wes-billboard__title field-billboardtitle">
    //                             News:&#160;Wesleyan Bank acquired by Hampshire
    //                             Trust Bank&#160;
    //                           </h2>
    //                           <div className="wes-billboard__message">
    //                             <p>
    //                               Wesleyan Assurance Society has agreed to sell
    //                               Wesleyan Bank to Hampshire Trust Bank, a
    //                               specialist bank that supports UK retail and
    //                               business customers.&nbsp;
    //                               <br />
    //                               <br />
    //                               The sale, which remains subject to regulatory
    //                               approval is anticipated to complete later this
    //                               year and we look forward to sharing more news
    //                               on this exciting development in the coming
    //                               months.&nbsp;&nbsp;
    //                             </p>
    //                             <p>
    //                               Meanwhile, it remains business as usual for
    //                               all Wesleyan Bank customers who can continue
    //                               to access their accounts, make repayments and
    //                               contact us in the usual way.&nbsp;
    //                             </p>
    //                             <p>
    //                               Thank you for your ongoing support of our
    //                               business, we remain committed to meeting your
    //                               ongoing financial needs both now and in the
    //                               future.
    //                             </p>
    //                           </div>
    //                           <div className="wes-billboard__actions">
    //                             <a
    //                               data-variantitemid="{6F603F2C-F30C-41CE-A134-39A6E61AF88C}"
    //                               className="wes-billboard__cta wes-button wes-button--on-light"
    //                               href="news/2021/10/wesleyan-bank-sale.html"
    //                               data-variantfieldname="BillboardCTA"
    //                             >
    //                               Read more information
    //                             </a>
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="wes-billboard__bg">
    //                         <BackgroundImage
    //                           url=""
    //                           className="wes-billboard__bg-image"
    //                           style={{
    //                             backgroundSize: "cover",
    //                             backgroundPosition: "50% 50%",
    //                             backgroundRepeat: "no-repeat",
    //                             position: "absolute",
    //                           }}
    //                           fluid={imageSection}
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>{" "}
    //                 </div>

    //                 <div className="component wes-card-deck">
    //                   <div className="wes-card-deck__wrapper">
    //                     <div className="wes-card-deck__header">
    //                       <div className="wes-card-deck__header-content">
    //                         <h2 className="wes-card-deck__title field-carddecktitle">
    //                           Information for Wesleyan Bank customers
    //                         </h2>
    //                       </div>
    //                     </div>
    //                     <div className="wes-card-deck__deck">
    //                       <div className="component wes-card-deck__deck-item">
    //                         <article className="wes-card-deck__card">
    //                           <div
    //                             className="wes-card-deck__card-bg"
    //                             style={{
    //                               backgroundImage:
    //                                 "url(-/media/feature/xacustomcomponents/core/card-deck-item/thumbnails/572x320.jfif)",
    //                             }}
    //                           ></div>
    //                           <div className="wes-card-deck__card-icon"></div>
    //                           <div className="wes-card-deck__card-body">
    //                             <div className="wes-card-deck__card-header">
    //                               <h2 className="wes-card-deck__card-title field-cardtitle">
    //                                 {" "}
    //                                 Interest rates
    //                               </h2>
    //                             </div>
    //                             <div className="wes-card-deck__card-message">
    //                               <p>
    //                                 Not sure what interest rate you’re getting
    //                                 on your account? Check out our latest and
    //                                 historic interest rates here.
    //                               </p>
    //                             </div>
    //                             <div className="wes-card-deck__card-footer">
    //                               <a
    //                                 href="wesleyan-bank/interest-rates.html"
    //                                 data-variantitemid="{271BB905-B65F-4855-9B46-DD030D52B833}"
    //                                 data-variantfieldname="CardLink"
    //                                 className="wes-card-deck__card-cta icon-right-arrow"
    //                               >
    //                                 View interest rates
    //                               </a>
    //                             </div>
    //                           </div>
    //                         </article>{" "}
    //                       </div>

    //                       <div className="component wes-card-deck__deck-item">
    //                         <article className="wes-card-deck__card">
    //                           <div
    //                             className="wes-card-deck__card-bg"
    //                             style={{
    //                               backgroundImage:
    //                                 "url(-/media/feature/xacustomcomponents/core/card-deck-item/thumbnails/572x320.jfif)",
    //                             }}
    //                           ></div>
    //                           <div className="wes-card-deck__card-icon"></div>
    //                           <div className="wes-card-deck__card-body">
    //                             <div className="wes-card-deck__card-header">
    //                               <h2 className="wes-card-deck__card-title field-cardtitle">
    //                                 {" "}
    //                                 Account tariff
    //                               </h2>
    //                             </div>
    //                             <div className="wes-card-deck__card-message">
    //                               <p>
    //                                 Got a personal account with Wesleyan Bank?
    //                                 Paying off a personal loan? Make sure you’re
    //                                 familiar with this list of potential
    //                                 charges.
    //                               </p>
    //                             </div>
    //                             <div className="wes-card-deck__card-footer">
    //                               <a
    //                                 href="wesleyan-bank/account-tariffs.html"
    //                                 data-variantitemid="{737A72EB-3B17-4DA7-98EE-5928CE8EEE8D}"
    //                                 data-variantfieldname="CardLink"
    //                                 className="wes-card-deck__card-cta icon-right-arrow"
    //                               >
    //                                 See the tariff
    //                               </a>
    //                             </div>
    //                           </div>
    //                         </article>{" "}
    //                       </div>

    //                       <div className="component wes-card-deck__deck-item">
    //                         <article className="wes-card-deck__card">
    //                           <div
    //                             className="wes-card-deck__card-bg"
    //                             style={{
    //                               backgroundImage:
    //                                 "url(-/media/feature/xacustomcomponents/core/card-deck-item/thumbnails/572x320.jfif)",
    //                             }}
    //                           ></div>
    //                           <div className="wes-card-deck__card-icon"></div>
    //                           <div className="wes-card-deck__card-body">
    //                             <div className="wes-card-deck__card-header">
    //                               <h2 className="wes-card-deck__card-title field-cardtitle">
    //                                 {" "}
    //                                 Legal information
    //                               </h2>
    //                             </div>
    //                             <div className="wes-card-deck__card-message">
    //                               <p>
    //                                 View Wesleyan Bank’s legal notice, including
    //                                 details on our complaints procedure and how
    //                                 we use your personal data.
    //                               </p>
    //                             </div>
    //                             <div className="wes-card-deck__card-footer">
    //                               <a
    //                                 href="wesleyan-bank/legal.html"
    //                                 data-variantitemid="{C1BD1DC9-DC9D-488F-A466-8F8623B45261}"
    //                                 data-variantfieldname="CardLink"
    //                                 className="wes-card-deck__card-cta icon-right-arrow"
    //                               >
    //                                 Read legal info
    //                               </a>
    //                             </div>
    //                           </div>
    //                         </article>{" "}
    //                       </div>

    //                       <div className="component wes-card-deck__deck-item">
    //                         <article className="wes-card-deck__card">
    //                           <div
    //                             className="wes-card-deck__card-bg"
    //                             style={{
    //                               backgroundImage:
    //                                 "url(-/media/feature/xacustomcomponents/core/card-deck-item/thumbnails/572x320.jfif)",
    //                             }}
    //                           ></div>
    //                           <div className="wes-card-deck__card-icon"></div>
    //                           <div className="wes-card-deck__card-body">
    //                             <div className="wes-card-deck__card-header">
    //                               <h2 className="wes-card-deck__card-title field-cardtitle">
    //                                 {" "}
    //                                 Executive team
    //                               </h2>
    //                             </div>
    //                             <div className="wes-card-deck__card-message">
    //                               <p>
    //                                 It’s always good to know who you’re banking
    //                                 with. Here you can meet the executive team
    //                                 behind Wesleyan Bank.
    //                               </p>
    //                             </div>
    //                             <div className="wes-card-deck__card-footer">
    //                               <a
    //                                 href="wesleyan-bank/executive-team.html"
    //                                 data-variantitemid="{6F19F665-D3D1-4809-86CA-5A4CA05B664D}"
    //                                 data-variantfieldname="CardLink"
    //                                 className="wes-card-deck__card-cta icon-right-arrow"
    //                               >
    //                                 Meet the team
    //                               </a>
    //                             </div>
    //                           </div>
    //                         </article>{" "}
    //                       </div>

    //                       <div className="component wes-card-deck__deck-item">
    //                         <article className="wes-card-deck__card">
    //                           <div
    //                             className="wes-card-deck__card-bg"
    //                             style={{
    //                               backgroundImage:
    //                                 "url(-/media/feature/xacustomcomponents/core/card-deck-item/thumbnails/572x320.jfif)",
    //                             }}
    //                           ></div>
    //                           <div className="wes-card-deck__card-icon"></div>
    //                           <div className="wes-card-deck__card-body">
    //                             <div className="wes-card-deck__card-header">
    //                               <h2 className="wes-card-deck__card-title field-cardtitle">
    //                                 {" "}
    //                                 Support for existing loans
    //                               </h2>
    //                             </div>
    //                             <div className="wes-card-deck__card-message">
    //                               <p>
    //                                 Do you have an outstanding loan agreement
    //                                 with Wesleyan Bank? Here's the important
    //                                 information and contact details you need.
    //                               </p>
    //                             </div>
    //                             <div className="wes-card-deck__card-footer">
    //                               <a
    //                                 href="wesleyan-bank/loan-support.html"
    //                                 data-variantitemid="{AE09B530-5E57-4CB8-B4BF-9BCAB6F1A0A7}"
    //                                 data-variantfieldname="CardLink"
    //                                 className="wes-card-deck__card-cta icon-right-arrow"
    //                               >
    //                                 Support for loan customers
    //                               </a>
    //                             </div>
    //                           </div>
    //                         </article>{" "}
    //                       </div>
    //                     </div>
    //                   </div>{" "}
    //                 </div>

    //                 <div
    //                   className="component wes-promo wes-promo--shout wes-promo--grey-bottom"
    //                   role="banner"
    //                 >
    //                   <div className="wes-promo__wrapper">
    //                     <div className="wes-promo__body">
    //                       <div className="wes-promo__trapezoid"></div>
    //                       <div className="wes-promo__content">
    //                         <h2 className="wes-promo__title field-promo-title">
    //                           Manage your account online
    //                         </h2>
    //                         <div className="wes-promo__message">
    //                           <p>
    //                             Log in or register for Wesleyan Bank’s online
    //                             banking service – the simplest way to view or
    //                             manage your account.
    //                           </p>
    //                         </div>
    //                         <div className="wes-promo__actions">
    //                           <a
    //                             data-variantitemid="{9AE54D25-D6CD-49BA-914A-2D4357841B92}"
    //                             target="_blank"
    //                             rel="noopener noreferrer"
    //                             className="wes-button wes-button--on-gold button-icon-arrow-right"
    //                             href="https://ebanking.wesleyanbank.co.uk/CustomerLogins"
    //                             data-variantfieldname="Promo Link One"
    //                           >
    //                             Go to online banking
    //                           </a>
    //                         </div>
    //                         <div className="wes-promo__footnote">
    //                           <p className="field-promo-footnote"></p>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>{" "}
    //                 </div>

    //                 <div className="component wes-content-block wes-content-block--grey">
    //                   <div className="wes-content-block__wrapper">
    //                     <div className="wes-content-block__body">
    //                       <div className="wes-content-block__body-content">
    //                         <div className="wes-content-block__placeholder">
    //                           <div className="component wes-rich-text-scope">
    //                             <h2>Useful links</h2>
    //                             <ul className="wes-core-resource-link">
    //                               <li className="wes-font-icon-sheet">
    //                                 <a href="-/media/pdf/terms-wes-bank-general.pdf">
    //                                   Wesleyan Bank general terms and conditions
    //                                 </a>
    //                                 &nbsp;PDF (203KB)
    //                               </li>
    //                               <li className="wes-font-icon-sheet">
    //                                 <a href="-/media/pdf/bereavement-guide-wesleyan-bank.pdf">
    //                                   Bereavement guide
    //                                 </a>
    //                                 &nbsp;PDF (73KB)
    //                               </li>
    //                               <li className="wes-font-icon-web-link">
    //                                 <a
    //                                   rel="noopener noreferrer"
    //                                   href="http://www.fscs.org.uk"
    //                                   target="_blank"
    //                                 >
    //                                   FSCS website
    //                                 </a>
    //                               </li>
    //                             </ul>{" "}
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>{" "}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
  )
}

export default IndexPage
